import { getAddress } from "viem";
import { SerializedFarmConfig } from '@pancakeswap/farms'

const priceHelperLps: SerializedFarmConfig[] = [].map((p) => ({
    ...p,
    token: p.token.serialize,
    quoteToken: p.quoteToken.serialize,
    lpAddress: getAddress(p.lpAddress),
}))

export default priceHelperLps