import { SerializedFarmConfig } from '..'
import { defineFarmV3Configs } from '../src/defineFarmV3Configs'
import { coreTokens } from '@pancakeswap/tokens'
import { getAddress } from "viem";
import {FeeAmount} from "@pancakeswap/v3-sdk";

export const farmsV3 = defineFarmV3Configs([
    // make sure token0 as int is smaller than token1
    // {
    //     pid: 1,
    //     token0: coreTokens.usdt,
    //     token1: coreTokens.ice,
    //     lpAddress: '0x0e1a82C5733D51b5c2d8BcD745d0cB9b2B7dE554',
    //     feeAmount: FeeAmount.LOW,
    // },
])

const farms: SerializedFarmConfig[] = [
    // {
    //     pid: 0,
    //     lpSymbol: 'ICE-USDT LP',
    //     lpAddress: '0xf1a996efba43dcbd7945d2b91fa78420d9c23bf0',
    //     token: coreTokens.ice,
    //     quoteToken: coreTokens.usdt,
    // },
].map((p) => ({ ...p, token: p.token.serialize, quoteToken: p.quoteToken.serialize, lpAddress: getAddress(p.lpAddress), }))

export default farms
